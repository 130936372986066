import React, { useState } from 'react'

import { Typography } from '@telavita-core/react-design-kit'

import { ReactComponent as HelperIcon } from '../../static/svg/icon_help.svg'
import { ReactComponent as HyperlinkIcon } from '../../static/svg/icon_hyperlink.svg'
import AudioTranscriptionGuidanceModal from '../AudioTranscriptionGuidanceModal'

export default function AudioTranscriptionGuidance() {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div className='AudioTranscriptionGuidance'>
        <div className='AudioTranscriptionGuidance__header'>

          <div className='AudioTranscriptionGuidance__header__title'>
            <Typography 
              variant="content2" 
              weight='bold'
            >
              Transcrição de áudio
            </Typography>
            
            <div className="AudioTranscriptionGuidance__header__badge">
              <Typography>BETA</Typography>
            </div>
          </div>

          <HelperIcon 
            onClick={() => setShowModal(true)} 
            className='AudioTranscriptionGuidance__header__helper'
          />

        </div>

        <div className='AudioTranscriptionGuidance__description'>
          <Typography variant="content2">
            Antes de iniciar, abra a transcrição de áudio numa segunda aba e tenha o 
            resumo da consulta em texto, para facilitar o preenchimento do prontuário.
          </Typography>
        </div>

        <a 
          href='https://telavitascribe.com/login' 
          target='_blank' 
          rel='noopener noreferrer' 
          className='AudioTranscriptionGuidance__hyperlink'
        >
          <HyperlinkIcon />
          <Typography 
            color='primary' 
            variant="content2" 
            weight='bold'
          >
            Abrir transcrição de áudio
          </Typography>
        </a>
      </div>
      {showModal && (
        <AudioTranscriptionGuidanceModal onClose={() => setShowModal(false)} />
      )}
    </>
  )
}