import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { IconButton } from '@telavita-core/react-design-kit'

import { errorTypes } from '~/views/ErrorView/Errors'
import { views } from '~/views/viewsCode'

import AudioLevel from '~/components/AudioLevel'
import { FeedbackMessageManager, messageCode, messageComponents } from '~/components/FeedbackMessageManager'
import { ReadyToCallMessage } from '~/components/FeedbackMessageManager/messageComponents/ReadyToCallMessage'
import RequestTokenModal from '~/components/RequestTokenModal'
import SettingsButton from '~/components/SettingsButton'
import TipsMessageBox from '~/components/TipsMessageBox'

import { DevicesContext } from '~/contexts'
import { fetchApi, dateTimeHandler, browserDetectionHandler } from '~/handlers'
import { useNetwork, useProfile, useMedicalRecord } from '~/hooks'
import { axios, endpoints } from '~/settings'
import { ReactComponent as Logo } from '~/static/svg/logo-telavita.svg'
import { colors } from '~/utils/stylesConstants'

const { isSupportedBrowser, isSupportedVersion } = browserDetectionHandler()

const WaitingRoom = ({
  hash,
  updateView,
  accessToken,
  updateAccessToken,
  meeting,
  updateMeeting,
  onOpenSettingsModal,
}) => {
  const videoRef = useRef()
  const history = useHistory()
  const isOnline = useNetwork()
  const { isPatient, isProfessional } = useProfile({ user: meeting?.user })

  const { medicalRecord } = useMedicalRecord({ guest: meeting?.guest }, isProfessional)
  
  const {
    userAudioTrack,
    userVideoTrack,
    handleToggleAudio,
    handleToggleVideo,
    audioEnabled,
    videoEnabled,
    mediaErrorMessage,
  } = useContext(DevicesContext)

  const [loading, setLoading] = useState(false)
  const [roomState, setRoomState] = useState(messageCode.PREPARING_ROOM)
  const [openTokenModal, setOpenTokenModal] = useState(false)

  function handleErrorState() {
    setLoading(false)
    setRoomState(messageCode.UNKNOWN_ERROR)
  }

  function closeTokenModal() {
    setLoading(false)
    setOpenTokenModal(false)
  }

  const handleRedirectToCallRoom = async (hash) => {
    try {
      const response = await axios.post(endpoints.virtualRooms.JOIN(hash))
      const accessToken = response.data.data.access_token

      updateAccessToken(accessToken)
    } catch (err) {
      Sentry.captureException(err)
      handleErrorState()
    }
  }

  const joinCallRoom = async () => {
    setLoading(true)

    const { schedule } = meeting

    if (schedule) {
      if (schedule.tokenHasBeenFilled === false && isPatient) return setOpenTokenModal(true)

      handleRedirectToCallRoom(schedule.hash)
    } else handleErrorState()
  }

  const handleAuthentication = useCallback(async () => {
    const isDeviceSupported = isSupportedBrowser && isSupportedVersion

    try {
      const response = await axios.get(endpoints.virtualRooms.AUTH(hash))
      const { schedule, user, guest } = response.data.data
      const { timestamp } = response.headers

      /** Salvar a diferença de horário do servidor com  o local
      para a sincronização do timer na consulta */
      const endDate = schedule.end_date
      const serverTime = dateTimeHandler.getDateFromTimestamp(timestamp)
      const serverDuration = dateTimeHandler.getDurationInMs(serverTime, endDate) || 0
      const localDuration = dateTimeHandler.getDurationInMs(null, endDate) || 0
      const diffDurationToNormalize = serverDuration - localDuration

      updateMeeting({
        guest,
        user,
        schedule: {
          id: schedule.id,
          hash: schedule.virtual_room_hash,
          startDate: schedule.start_date,
          endDate: schedule.end_date,
          diffDuration: diffDurationToNormalize,
          interval: schedule.procedure.interval,
          guests: schedule.guests,
          planCode: schedule.plan_code,
          planGroupName: schedule.plan_group_name,
          planGroupCode: schedule.plan_group_code,
          tokenHasBeenFilled: schedule.token_has_been_filled,
        }
      })

      Sentry.configureScope(scope => scope.setUser({ email: user.email }))
      return fetchApi.sendLogToServer(schedule.virtual_room_hash, {
        event: 'GUEST_CONNECTED',
        is_supported: isDeviceSupported
      })
    } catch (err) {

      const handleErrorByStatusCode = {
        401: () => setRoomState(messageCode.ERROR_AUTH),
        403: () => updateView(views.faceId),
        404: () => setRoomState(messageCode.ROOM_NOT_FOUND),
        410: () => history.replace({
          pathname: 'error',
          state: { errorType: errorTypes.TIME_UP }
        })
      }

      if (err && handleErrorByStatusCode[err.status]) return handleErrorByStatusCode[err.status]()
      setRoomState(messageCode.UNKNOWN_ERROR)

    } finally {
      if (!isDeviceSupported) history.replace({
        pathname: 'error',
        state: { errorType: errorTypes.BROWSER }
      })
    }
  }, [])

  useEffect(() => {
    const storageKey = '@telavita-meet/disconnectData'
    const disconnectData = JSON.parse(localStorage.getItem(storageKey))

    if (disconnectData && isOnline) {
      setTimeout(() => {
        fetchApi.sendLogToServer(disconnectData.room, {
          event: 'GUEST_DISCONNECTED',
          value: disconnectData.value,
        })
        localStorage.removeItem(storageKey)
      }, 10000)
    }
  }, [isOnline])

  useEffect(() => {
    if (!hash || hash === 'null') {
      setRoomState(messageCode.ROOM_NOT_FOUND)
    } else {
      handleAuthentication()
    }
  }, [hash])

  useEffect(() => {
    if (userVideoTrack && videoRef.current) {
      const ms = new MediaStream()
      ms.addTrack(userVideoTrack)
      videoRef.current.srcObject = ms
    }
  }, [userVideoTrack])

  useEffect(() => {
    if (mediaErrorMessage) {
      setRoomState(messageCode.UNKNOWN_ERROR)
      Sentry.captureException(mediaErrorMessage)
    }
  }, [mediaErrorMessage])

  // Quando a Sala de Espera adquirir um `accessToken` válido, redireciona
  // para começar a videochamada
  useEffect(() => {
    if (accessToken) {
      updateView(views.callRoom)
    }
  }, [accessToken])

  return (
    <>
      <div className='WaitingRoom'>
        <div className='LogoContainer'> <Logo /> </div>
        <div className='WaitingRoom__main'>
          <div className={`VideoContainerBox${videoEnabled ? '' : '--disabled'}`}>
            <div className='FeedbackMessage'>
              {(!userVideoTrack || !userAudioTrack) && !mediaErrorMessage &&
                <p style={{ color: colors.GREY_07 }}>Conectando câmera e microfone</p>
              }
              {mediaErrorMessage &&
                <p style={{ color: colors.GREY_07 }}>{mediaErrorMessage}</p>
              }
              {!videoEnabled && audioEnabled &&
                <p style={{ color: colors.WHITE }}>Câmera desligada</p>
              }
              {!videoEnabled && !audioEnabled &&
                <p style={{ color: colors.WHITE }}>Câmera e microfone desligados</p>
              }
            </div>

            <div className='LocalVideo'>
              <video
                ref={videoRef}
                playsInline
                autoPlay
                muted
              />
            </div>

            <div className='Controls'>
              <div className='Controls__buttonContainer'>
                <IconButton
                  icon={audioEnabled ? 'MicrophoneOn' : 'MicrophoneOff'}
                  variant={audioEnabled ? 'contained' : 'outlined'}
                  color={!audioEnabled ? 'danger' : null}
                  tooltip={audioEnabled ? 'Desligar microfone' : 'Ligar microfone'}
                  onClick={handleToggleAudio}
                />
              </div>

              <AudioLevel isEnabled={audioEnabled} />

              <div className='Controls__buttonContainer'>
                <IconButton
                  icon={videoEnabled ? 'CameraOn' : 'CameraOff'}
                  variant={videoEnabled ? 'contained' : 'outlined'}
                  color={!videoEnabled ? 'danger' : null}
                  tooltip={videoEnabled ? 'Desligar vídeo' : 'Ligar vídeo'}
                  onClick={handleToggleVideo}
                />
              </div>

              <div className='Controls__buttonContainer'>
                <SettingsButton onClick={onOpenSettingsModal} hasError={mediaErrorMessage}/>
              </div>
            </div>
          </div>
          {
            meeting && !mediaErrorMessage
              ? <ReadyToCallMessage action={joinCallRoom} loading={loading} isProfessional={isProfessional} />
              : <FeedbackMessageManager message={messageComponents[roomState]} />
          }
        </div>

        <TipsMessageBox />
      </div>
      {openTokenModal && (
        <RequestTokenModal
          onClose={closeTokenModal}
          planGroupName={meeting.schedule.planGroupName}
          planGroupCode={meeting.schedule.planGroupCode}
          scheduleId={meeting.schedule.id}
          onRedirect={handleRedirectToCallRoom}
        />
      )}
    </>
  )
}

export default WaitingRoom
