import React from 'react'
import ReactDOM from 'react-dom'

import { motion, AnimatePresence } from 'framer-motion'

import { useDimensions } from '@telavita-core/react-design-kit'

const BaseModal = ({ children }) => {
  const { isMobile } = useDimensions()

  const backdropAnimationProps = isMobile ? {
    initial:{ opacity: 0 },
    animate:{ opacity: 0.9, transition: { duration: 0.1 } },
    exit:{ opacity: 0 }
  } : {}

  const modalAnimationProps = isMobile ? {
    initial:{ y: 500, opacity: 0 },
    animate:{ y: 0, opacity: 1, transition: { duration: 0.3  } },
    exit:{ y: 700, opacity: 0 }
  } : {}

  return ReactDOM.createPortal(
    <AnimatePresence>
      <motion.div
        className='Modal'
        {...modalAnimationProps}
      >
        <motion.div
          className='Modal__backdrop'
          {...backdropAnimationProps}
        ></motion.div>

        <div className='Modal__container'>
          {children}
        </div>
      </motion.div>
    </AnimatePresence>,
    document.body
  )
}

export default BaseModal