import React, { useState } from 'react'


import { Button, Typography } from '@telavita-core/react-design-kit'

import BaseModal from '../BaseModal'
import CustomIcons, { iconList } from '../CustomIcons'
import Tabs from '../Tabs'
import Doubts from './components/Doubts'
import HowToUse from './components/HowToUse'

const optionsTabs = [
  { title: 'Dúvidas', id: 1 },
  { title: 'Como usar', id: 2 },
]

export default function AudioTranscriptionGuidanceModal({ onClose }) {
  const [selectedTab, setSelectedTab] = useState(1)

  function handleChangeTab(id) {
    setSelectedTab(id)
  }

  return (
    <BaseModal>
      <div className='AudioTranscriptionGuidanceModal'>
        <Typography variant='header4' weight='bold'>Transcrição de áudio</Typography>

        <button className='AudioTranscriptionGuidanceModal__closeButton' onClick={onClose}>
          <CustomIcons
            icon={iconList.X}
          />
        </button>

        <div className='AudioTranscriptionGuidanceModal__tabs'>
          <Tabs
            tabs={optionsTabs}
            onSelectTab={handleChangeTab}
            selectedItem={selectedTab}
          />
        </div>

        <div className='AudioTranscriptionGuidanceModal__content'>
          {selectedTab === 1 && (
            <Doubts />
          )}

          {selectedTab === 2 && (
            <HowToUse />
          )}
        </div>
        <Button 
          onClick={onClose}
          customClassName='AudioTranscriptionGuidanceModal__confirm' 
          variant='contained'
        >
          Entendi
        </Button>
      </div>
    </BaseModal>
  )
}