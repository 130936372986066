import React from 'react'

import { Typography } from '@telavita-core/react-design-kit'

export default function Doubts() {
  return (
    <div className='AudioTranscriptionGuidanceModal__doubts'>
      <div>
        <Typography variant='content1' weight='bold'>O que é a transcrição de áudio?</Typography>
        <Typography variant='content2'>É uma ferramenta de inteligência artificial que cria um resumo em texto com base no que foi dito dentro da sala da consulta.</Typography>
      </div>
  
      <div>
        <Typography variant='content1' weight='bold'>Quais os benefícios de utilizar a transcrição de áudio? O uso é obrigatório?</Typography>
        <Typography variant='content2'>O uso é opcional. Porém, a Telavita recomenda a utilização, pois é um recurso que visa facilitar sua rotina, tornando o preenchimento do prontuário mais rápido e eficiente!</Typography>
      </div>
  
      <div>
        <Typography variant='content1' weight='bold'>Posso copiar e colar trechos do resumo no prontuário?</Typography>
        <Typography variant='content2'>Sim, mas <strong>você deve ler antes e estar de acordo com o que foi transcrito no resumo para utilizá-lo</strong>, pois a informação registrada no prontuário continua sendo de inteira responsabilidade do profissional.</Typography>
      </div>
  
      <div className='AudioTranscriptionGuidanceModal__doubts__last'>
        <Typography variant='content1' weight='bold'>É seguro utilizar esta ferramenta?</Typography>
        <Typography variant='content2'>Sim, a transcrição ocorre em conformidade com a LGPD e o CFP. Não se trata de uma transcrição literal de cada palavra dita na consulta, e sim um resumo para te auxiliar no preenchimento do prontuário</Typography>
        <Typography variant='content2'>O resumo não fica gravado e é emitido de forma anonimizada, permitindo o profissional consultá-lo e incluir o que achar necessário no prontuário oficial.</Typography>
        <Typography variant='content2'> Este resumo segue os pontos do método SOAP de registro de dados clínicos que contém Subjetivo, Objetivo, Avaliação e Plano de forma resumida, como previsto na resolução 001/2009 
          § 1º: <i>O registro documental em papel ou informatizado tem caráter sigiloso e constitui-se um conjunto de informações que tem por objetivo contemplar de forma sucinta o trabalho prestado.</i></Typography>
      </div>
    </div>
  )
}