import React from 'react'

import { Typography } from '@telavita-core/react-design-kit'

import Image1 from '../../../static/images/audio-transcription-modal/image1.jpg'
import Image2 from '../../../static/images/audio-transcription-modal/image2.jpg'
import Image3 from '../../../static/images/audio-transcription-modal/image3.jpg'
import Image4 from '../../../static/images/audio-transcription-modal/image4.jpg'
import Image5 from '../../../static/images/audio-transcription-modal/image5.jpg'
import Image6 from '../../../static/images/audio-transcription-modal/image6.jpg'
import Image7 from '../../../static/images/audio-transcription-modal/image7.jpg'

export default function HowToUse() {
  return (
    <div className='AudioTranscriptionGuidanceModal__howToUse'>
      <div>
        <Typography variant='content2'>
          <strong>1) </strong>Na sala de espera da consulta, abra o link da transcrição de áudio antes de iniciar a consulta.
        </Typography>
        <picture>
          <img src={Image1} alt="Tela de espera da consulta com link para transcrição de áudio" />
        </picture>
      </div>

      <div>
        <Typography variant='content2'><strong>2) </strong>Entre com seu e-mail cadastrado na Telavita ou com sua conta Google. Caso escolha a primeira opção, você deve checar sua caixa de email e entrar pelo link de confirmação enviado pela ferramenta, chamada Scribe.</Typography>
        <picture>
          <img src={Image2} alt="Tela de login da transcrição de áudio" />
        </picture>
      </div>

      <div>
        <Typography variant='content2'><strong>3) </strong>Ao logar-se, você deve clicar no botão “começar nova sessão”.</Typography>
        <picture>
          <img src={Image3} alt="Tela de início da transcrição de áudio" />
        </picture>
      </div>

      <div>
        <Typography variant='content2'><strong>4) </strong>O navegador pedirá a permissão para utilizar seu microfone. Clique em “permitir”.</Typography>
        <picture>
          <img src={Image4} alt="Tela de permissão de microfone" />
        </picture>
      </div>

      <div>
        <Typography variant='content2'><strong>5) </strong>Para iniciar a sessão de transcrição, você deve definir o dispositivo de entrada e modo de áudio. Uma vez definidos, o botão <strong>“iniciar sessão”</strong> será liberado.</Typography>
        <picture>
          <img src={Image5} alt="Tela de configuração de dispositivo" />
        </picture>
      </div>

      <div>
        <Typography variant='content2'><strong>6) </strong>Assim que visualizar <strong>“Em sessão”</strong> na tela, mantenha a página da transcrição aberta e vá para a página da Telavita para iniciar a consulta. As duas páginas devem permanecer abertas, uma em cada aba no seu navegador.</Typography>
        <picture>
          <img src={Image6} alt="Tela de permissão de microfone" />
        </picture>
      </div>

      <div>
        <Typography variant='content2'><strong>7) </strong>Realize a consulta normalmente. Ao concluí-la, você deve ir novamente à página da transcrição e clicar no botão <strong>“finalizar sessão”</strong>, para que o resumo da sessão seja gerado (pode levar alguns minutos) e utilizado no preenchimento do prontuário.</Typography>
        <picture>
          <img src={Image7} alt="Tela de permissão de microfone" />
        </picture>
      </div>
    </div>
  )
}